<template>
    <div class="level_index level_indexI">

        <div class="search">
            <el-form :inline="true">

                <el-form-item>
                    <el-input clearable v-model="data.order_no" placeholder="请输入订单编号" />
                </el-form-item>


                <el-form-item label="支付类型">
                    <!-- 1微信 2对公 -->
                    <el-select clearable v-model="data.payment_id" placeholder="请选择支付类型" class="SelfSelect">
                        <el-option :label="'在线支付'" :value="'1'"></el-option>
                        <el-option :label="'对公打款'" :value="'2'"></el-option>
                    </el-select>
                </el-form-item>


                <el-form-item>

                    <el-button type="primary" @click="search()">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <!-- <el-button type="primary" @click="addItem">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        添加会员卡
                    </el-button> -->

                </el-form-item>



                <el-form-item>
                    <json-excel :data="json_data" :fields="json_fields" worksheet="当月" name="缴费单.xls"
                        :header="[header1,header2]" :footer="[footer1,footer2]">
                        <el-button type="primary">导出缴费单</el-button>
                    </json-excel>
                </el-form-item>







            </el-form>
        </div>

        <el-table class="el-tableII FanfuTableBox" :data="list" stripe height="100%" border ref="multipleTable">

            <el-table-column show-overflow-tooltip type="selection" width="40" align="center" fixed="left" />
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" />

            <el-table-column show-overflow-tooltip prop="order_no" label="订单号" align="left" width="170" />

            <el-table-column show-overflow-tooltip prop="img" label="类型封面" align="center" width="100">
                <template slot-scope="scope">
                    <img src="" alt="" v-bind:src="GLOBAL.BASE_URL +scope.row.renewlog.img"
                        v-if="scope.row.renewlog.img" class="CoverImage">
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="title" label="商品名称" align="center" width="100">
                <template slot-scope="scope">
                    {{scope.row.renewlog.title}}
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="title" label="销售价格" align="center" width="100">
                <template slot-scope="scope">
                    {{scope.row.renewlog.money}}
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="pay_price" label="支付金额(元)" align="center" />


            <el-table-column show-overflow-tooltip label="支付方式" align="center" width="100">
                <template slot-scope="scope">
                    <!-- {{scope.row.payment_id==1?"在线支付":"对公打款"}} -->

                    <!-- //1微信  2对公 -->
                    <el-tag type="" v-if="scope.row.payment_id==1">在线支付</el-tag>
                    <el-tag type="success" v-if="scope.row.payment_id==2">对公打款</el-tag>

                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip prop="pay_time" label="支付时间" align="left" />

            <el-table-column show-overflow-tooltip prop="create_time" label="创建时间" align="left" />

            <el-table-column show-overflow-tooltip prop="pay_status" label="支付状态" align="center">
                <template slot-scope="scope">
                    <span :style="{'color':scope.row.pay_status==1?'green':'#ccc'}">
                        {{payStatus(scope.row.pay_status)}}
                    </span>
                </template>
            </el-table-column>

            <!--  
                status: 1 -->
            <!-- "订单状态: 0未完成、1已完成 -->
            <el-table-column show-overflow-tooltip prop="status" label="订单状态" align="center">
                <template slot-scope="scope">
                    <!-- {{orderStatus(scope.row.status)}} -->
                    <!-- <span v-html="orderStatus(scope.row.status)"></span> -->

                    <el-tag type="danger" v-if="scope.row.status==0">未完成</el-tag>
                    <el-tag type="success" v-if="scope.row.status==1">已完成</el-tag>

                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <div class="operate1">

                        <el-button v-if="scope.row.pay_status==0&&scope.row.payment_id==2" type="" class="blue"
                            @click="payJudge(scope.row)">
                            支付
                        </el-button>

                        <el-button type="" class="white" @click="info(scope.row)">
                            详情
                        </el-button>

                        <!-- 
                        <el-button type="" class="pink" @click="del(scope.row)">
                            删除
                        </el-button> -->
                        <!-- 
                        <el-button type="" class="green" @click="">
                            复制
                        </el-button>
                        <el-button type="" class="dirtyblue" @click="">
                            上移
                        </el-button>
                        <el-button type="" class="dirtybown" @click="">
                            下移
                        </el-button>
                        -->

                    </div>
                </template>
            </el-table-column>

        </el-table>

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.page_size" :total="data.total" @current-change="handleCurrentChange" />

        <orderListpop ref="orderListpop" @refresh="getMedicalWasteTypes" />

    </div>
</template>

<script>
    import JsonExcel from 'vue-json-excel'


    import orderListpop from './components/orderListpop'

    import resizeDetector from 'element-resize-detector'
    import {
        color
    } from 'echarts';

    export default {
        components: {
            orderListpop,
            JsonExcel
        },
        data() {
            return {

                data: {
                    payment_id: null, //1微信  2对公
                    order_no: "",
                    // type: "",
                    page: 1,
                    page_size: 15,
                    total: 0,
                },

                list: [],


                // 类型：1年、2月、3日
                timeTypt: [{
                    id: 1,
                    name: '年',
                }, {
                    id: 2,
                    name: '月',
                }, {
                    id: 3,
                    name: '日',
                }, ],


                header1: "<div style='background-color:#23ffed'><b style='width:50%;text-align:center;'>小标题</b><b style='width:50%;'>小标题</b></div>",
                header2: "<h3 style='color:red;text-align:right;'>小标题</h3>",
                footer1: "<h3 style='color:red;text-align:right;'>合计</h3>",
                footer2: "<h3 style='color:red;text-align:right;background-color:#23ffed'>合计</h3>",
                json_fields: {
                    '医院名称': 'name',
                    '医院性质': 'name',
                    '下属民营数量': 'name',
                    '下属民营名称': 'name',
                    '状态': 'name',
                    '费用单价（元）': 'name',
                    '缴费时长（月）': 'name',
                    '缴费总额（元）': 'name',
                    '缴费日期': 'name',
                    '开始日期': 'name',
                    '结束日期': 'name',
                    '备注': 'name',
                    '': {
                        callback: () => {
                            return ""
                        }
                    },
                    '缴费时长（月） ': 'name',
                    '缴费总额（元） ': 'name',
                    '缴费日期 ': 'name',
                    '开始日期 ': 'name',
                    '结束日期 ': 'name',
                    '对应订单ID ': 'name',
                    '备注 ': 'name',

                    '电话 ': {
                        field: 'phone.mobile',
                        callback: (value) => {
                            return `前缀 - ${value}`;
                        }
                    },
                },
                json_data: [{
                        'name': '名字',
                        'phone': {
                            'mobile': '1-541-754-3010',
                        }
                    }, {
                        'name': '名字',
                        'phone': {
                            'mobile': '1-541-754-3010',
                        }
                    }, {
                        'name': '名字',
                        'phone': {
                            'mobile': '1-541-754-3010',
                        }
                    }, {
                        'name': '名字',
                        'phone': {
                            'mobile': '1-541-754-3010',
                        }
                    }, {
                        'name': '名字',
                        'phone': {
                            'mobile': '1-541-754-3010',
                        }
                    }, {
                        'name': '<h3 style="color:red;text-align:right;">合计</h3>',
                        'phone': {
                            'mobile': '<img width="30" height="30"  src="https://www.baidu.com/img/flexible/logo/pc/result.png">',
                        }
                    }, {
                        'name': '名字',
                        'phone': {
                            'mobile': "<b style='color:red;text-align:right;'>合计</b>",
                        }
                    },

                ],



            }
        },

        computed: {
            // ...mapState('m_item', ['MedicalWasteItem']),
        },

        created() {
            // this.getList()
            this.modifyLayout();


            setTimeout(() => {
                this.asdasd()
            }, 3000)


        },

        mounted() {
            this.modifyLayout();
            this.getMedicalWasteTypes()
        },

        watch: {

            // "data": {
            //     deep: true,
            //     handler: function(newVal, oldVal) {
            //     }
            // },

            "data.payment_id": {
                deep: true,
                handler: function(newVal, oldVal) {

                    if (this.data.payment_id == "" || this.data.payment_id == null) {
                        delete this.data.payment_id
                    }

                    this.data.page = 1
                    this.getMedicalWasteTypes()

                }
            },

        },

        methods: {
            // ...mapMutations('m_item', ['setMedicalWasteItem']),

            renewType(type) {
                // 类型：1年、2月、3日
                switch (type) {
                    case 1:
                        return "年"
                        break;
                    case 2:
                        return "月"
                        break;
                    default:
                        return "日"
                        break;
                }
            },

            payStatus(type) {
                // 支付状态 (0未支付，1已支付,2已退款,3部分退款),
                switch (type) {
                    case 0:
                        return "未支付"
                        break;
                    case 1:
                        return "已支付"
                        break;
                    case 2:
                        return "已退款"
                        break;
                    default:
                        return "部分退款"
                        break;
                }
            },

            // orderStatus(type) {
            //     // status 0未完成 1已完成
            //     switch (type) {
            //         case 0:
            //             // return "未完成"
            //             return "<span style='color:#f55'>未完成</span>"
            //             break;
            //         default:
            //             // return "已完成"
            //             return "<span style='color:green'>已完成</span>"
            //             break;
            //     }
            // },

            getMedicalWasteTypes() {

                this.$http.get('/api/reneworder', this.data).then((res) => {

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);
                    }
                    if (res.code == 200) {

                        this.list = res.data.data
                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }
                }).catch((err) => {
                    console.log(err)
                });
            },

            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.data.page = val
                this.getMedicalWasteTypes()
            },

            search() {
                this.data.page = 1
                this.getMedicalWasteTypes()
            },

            addItem() {
                this.$refs.memberType.open()
                // this.$refs.memberTicket.open()

            },

            edit(row) {
                console.log(row)
                this.$refs.memberType.edit(row)
                // this.$refs.memberTicket.edit(row)
            },

            switchStatus(row) {
                // this.$http.put('/api/renew/setIsPublic/' + row.id, {
                // }).then((res) => {
                //     // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                //     if (res.code != 200) { //请求错误
                //         row.status = a
                //         this.$message.error(res.msg);
                //     }
                //     if (res.code == 200) {
                //         this.$message.success(res.msg);
                //         // this.close()
                //     }
                // }).catch((err) => {
                //     console.log(err)
                // });
            },

            info(row) {
                console.log(row)
                this.$refs.orderListpop.open(row)
            },

            payJudge(row) {

                const h = this.$createElement;
                this.$msgbox({
                    title: '支付',
                    message: h('p', null, [
                        h('span', null, '该订单确定已支付成功？'),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '支付成功',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {

                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '执行中...';

                            // setTimeout(() => {
                            //     done();
                            //     setTimeout(() => {
                            //         instance.confirmButtonLoading = false;
                            //     }, 300);
                            // }, 3000);


                            this.$http.put('/api/reneworder/confirmPay/' + row.id).then((res) => {
                                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                                if (res.code == 200) {
                                    this.$message.success(res.msg);
                                    this.getMedicalWasteTypes()

                                    done();
                                    setTimeout(() => {
                                        instance.confirmButtonLoading = false;
                                    }, 300);

                                }

                                if (res.code != 200) { //请求错误
                                    this.$message.error(res.msg);
                                }

                            }).catch((err) => {
                                console.log(err)
                            });

                        } else {
                            done();
                        }
                    }
                }).then(action => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'action: ' + action
                    // });
                });

            },

            modifyLayout() {
                const erd = resizeDetector();

                erd.listenTo(document.getElementsByClassName("search"), element => {
                    const width = element.offsetWidth;
                    const height = element.offsetHeight;

                    var a = 141 + 60 - 35 + height - 30;
                    if ($(".level_indexI .FanfuTableBox")[0]) {
                        $(".level_indexI .FanfuTableBox")[0].setAttribute("style", 'height: calc(100vh - ' + a +
                            'px) !important');
                    }

                    var b = 141 + 60 + 33 + height - 30;
                    if ($(".level_indexI .el-table")[0]) {
                        $(".level_indexI .el-table")[0].setAttribute("style", 'height: calc(100vh - ' + b +
                            'px) !important');
                    }

                });

            },


            asdasd() {


            },





        },

    }
</script>

<style lang="scss" scoped>
    .operate1 {
        // width: 400px;
        // display: block;

        // border: 1px red solid;
        // box-sizing: border-box;

        .el-button {
            color: #fff;
            border: none;
            padding: 0 20px !important;
        }

        .white {
            color: #606266;
            border: 1px #DCDFE6 solid;
            box-sizing: border-box;
        }

        .blue {
            background-color: #768DFC;
        }

        .green {
            background-color: #5BD995;
        }

        .dirtyblue {
            background-color: #4EBBC8;
        }

        .dirtybown {
            background-color: #B99216;
        }

        .pink {
            background-color: #FF754C;
        }

    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  }

  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini {
    border: 1px red dotted;
    box-sizing: border-box;
  } */


    /* .FanfuTableBox {
        border: 1px red dotted;
        box-sizing: border-box;
    } */
</style>